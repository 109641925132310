import React, { useEffect } from 'react';
import styled from 'styled-components';
import useForm from 'react-hook-form';
import axios from 'axios';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { PageTitle } from '../components/pages';
import '../components/layout.css';

import logo from '../images/logo@2x.png';

const Page = styled.div`
    background-color: #000;
    padding-top: 60px;
    padding-bottom: 120px;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media (min-width: 768px) {
        padding-top: 100px;
    }
    
    form {
      width: 100%;
      max-width: 700px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
`;

const RegOKPageTitle = styled(PageTitle)`
  color: #F5F3F0;
  text-align: center;
`;

const RegOKDesc = styled.div`
  font-family: "Montserrat Medium", sans-serif;
  font-size: 18px;
  text-align: center;
`;

const RegOKButton = styled.a`
  font-family: "Montserrat Medium", sans-serif;
  color: #F5F3F0;
  padding-top: 10px;
  text-transform: uppercase;
  font-size: 14px;
`;

const Input = styled.input`
  width: 90%;
  height: 50px;
  color: #000;
  background: #fff;
  font-family: "Montserrat Medium", serif;
  border: 0;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  border-radius: 0;
`;

const Select = styled.select`
  width: 90%;
  font-family: "Montserrat Medium", serif;
  background: #fff;
  border: 0;
  height: 50px;
  border-radius: 0;
  font-size: 16px;
  margin-bottom: 40px;
`;

const Label = styled.label`
  font-family: "Montserrat Medium", serif;
  font-size: 16px;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 90%;
`;

const LinkLogo = styled((props) => <Link {...props} />)`
  max-width: 160px;
  max-height: 18px;
  text-decoration: none;
  margin-bottom: 50px;
  img {
  filter: brightness(2);
  }
`;

const HeaderText = styled.h1`
  font-family: "Morganite Black", serif;
  font-size: 60px;
  color: #fff;
  text-transform: uppercase;
`;

const Submit = styled.input`
  background: #FD5A47;
  color: #fff;
  margin: 40px 0 0;
  font-family: "Montserrat Bold", serif;
  text-transform: uppercase;
  font-size: 16px;
  padding: 10px 15px;
  width: 90%;
  border: 0;
  border-radius: 0;
`;

const SuccessText = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
  font-family: "Morganite Black", serif;
  font-size: 60px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
`;

const ErrorText = styled.div`
  font-family: "Montserrat Bold", serif;
  text-transform: uppercase;
  font-size: 16px;
  color: #FD5A47;
  width: 90%;
  text-align: center;
`;

export default function WorkshopsRegister() {
  const {
    register, handleSubmit, errors,
  } = useForm();
  const [workshops, setWorkshops] = React.useState([]);
  const [responseError, setResponseError] = React.useState(false);
  const [noSpace, setNoSpace] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const onSubmit = (data) => {
    axios({
      method: 'post',
      url: 'https://baza-app.herokuapp.com/api/workshop_attendees',
      data: {
        workshop_attendee: data,
      },
    }).then((response) => {
      if (response) {
        if (response.status === 201) {
          setSuccess(true);
        } else if (response.status === 422) {
          setNoSpace(true);
        } else {
          setResponseError(true);
        }
      }
    })
      .catch((errors) => {
        setResponseError(true);
        console.error(errors);
      });
  };

  const getWorkshops = async () => axios.get('https://baza-app.herokuapp.com/api/workshops').then((response) => response);

  useEffect(() => {
    getWorkshops().then((response) => {
      setWorkshops(response.data);
    });
  }, []);

  return (
    <Page>
      <LinkLogo
        to="/#"
      >
        <img
          style={{
            maxWidth: '160px',
          }}
          src={logo}
          alt="Baza Centrum 2019"
        />
      </LinkLogo>
      {success
        ? <SuccessText>Rejestracja przebiegła pomyślnie.</SuccessText>
        : (
          <>
            <HeaderText>Warsztaty</HeaderText>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Label>Imię i nazwisko</Label>
              <Input name="full_name" ref={register} />
              {errors.full_name && <span>This field is required</span>}
              <Label htmlFor="workshop_id">Wybierz warsztat</Label>
              <Select id="workshop_id" name="workshop_id" ref={register({ required: true })}>
                {workshops.map((workshop) => (
                  <option key={workshop.id} value={workshop.id}>{workshop.name}</option>
                ))}
              </Select>
              {responseError && <ErrorText>Coś poszło nie tak, spróbuj ponownie później</ErrorText>}
              {noSpace && <ErrorText>Brak miejsc na tym warsztacie</ErrorText>}
              <Submit type="submit" value="Wyślij" />
            </form>
          </>
        )}
    </Page>
  );
}
